import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { formatNumber } from './format';

export async function exportDomainOverviewPDF(
  domain: string,
  metrics: any,
  chartsRef: React.RefObject<HTMLDivElement>
) {
  try {
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const margin = 20;
    let yPos = margin;

    // Helper function to check if we need a new page
    const checkNewPage = (height: number) => {
      if (yPos + height > pageHeight - margin) {
        pdf.addPage();
        yPos = margin;
        return true;
      }
      return false;
    };

    // Add title and date
    pdf.setFontSize(24);
    pdf.setTextColor(31, 41, 55); // text-gray-900
    pdf.text(`Domain Overview: ${domain}`, margin, yPos);
    yPos += 10;

    pdf.setFontSize(10);
    pdf.setTextColor(107, 114, 128); // text-gray-500
    pdf.text(`Generated on ${new Date().toLocaleDateString()}`, margin, yPos);
    yPos += 15;

    // Add metrics section
    const metricsData = [
      { label: 'Domain Rank', value: metrics.domainRank },
      { label: 'Organic Traffic', value: metrics.organicTraffic },
      { label: 'Keywords', value: metrics.keywords },
      { label: 'Backlinks', value: metrics.backlinks },
      { label: 'Referring Domains', value: metrics.referringDomains },
      { label: 'Broken Pages', value: metrics.brokenPages },
      { label: 'Broken Backlinks', value: metrics.brokenBacklinks },
      { label: 'IPs', value: metrics.ips },
      { label: 'Subnets', value: metrics.subnets }
    ];

    // Create metrics grid (3 columns)
    const colWidth = (pageWidth - 2 * margin) / 3;
    const rowHeight = 20;
    
    metricsData.forEach((metric, index) => {
      const col = index % 3;
      const row = Math.floor(index / 3);
      const x = margin + (col * colWidth);
      const y = yPos + (row * rowHeight);

      // Draw box
      pdf.setFillColor(249, 250, 251); // bg-gray-50
      pdf.setDrawColor(229, 231, 235); // border-gray-200
      pdf.roundedRect(x, y, colWidth - 5, rowHeight - 2, 2, 2, 'FD');

      // Add text
      pdf.setTextColor(107, 114, 128); // text-gray-500
      pdf.setFontSize(8);
      pdf.text(metric.label, x + 5, y + 6);

      pdf.setTextColor(31, 41, 55); // text-gray-900
      pdf.setFontSize(12);
      pdf.setFont('helvetica', 'bold');
      pdf.text(formatNumber(metric.value), x + 5, y + 15);
    });

    yPos += (Math.ceil(metricsData.length / 3) * rowHeight) + 15;

    // Add charts if available
    if (chartsRef.current) {
      const charts = chartsRef.current.querySelectorAll('.chart-section');
      
      for (const chart of Array.from(charts)) {
        const canvas = await html2canvas(chart as HTMLElement, {
          scale: 2,
          logging: false,
          useCORS: true,
          allowTaint: true,
          backgroundColor: '#ffffff'
        });

        const imgData = canvas.toDataURL('image/png');
        const imgWidth = pageWidth - (2 * margin);
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        // Check if we need a new page
        checkNewPage(imgHeight + 10);

        // Add chart title
        const title = chart.querySelector('h2')?.textContent || '';
        pdf.setFontSize(14);
        pdf.setTextColor(31, 41, 55);
        pdf.setFont('helvetica', 'bold');
        pdf.text(title, margin, yPos);
        yPos += 8;

        // Add chart image
        pdf.addImage(imgData, 'PNG', margin, yPos, imgWidth, imgHeight);
        yPos += imgHeight + 15;
      }
    }

    // Save the PDF
    pdf.save(`${domain}-overview.pdf`);
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw new Error('Failed to generate PDF');
  }
}
