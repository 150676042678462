import React, { useState, useEffect } from 'react';

interface SeedKeywordFiltersProps {
  filters: {
    keyword?: string;
    excludeKeyword?: string;
    intent?: string;
    minVolume?: number;
    maxVolume?: number;
    minCpc?: number;
    maxCpc?: number;
    minKd?: number;
    maxKd?: number;
  };
  onChange: (filters: any) => void;
  onApply: () => void;
  onClear: () => void;
}

export function SeedKeywordFilters({ 
  filters, 
  onChange,
  onApply,
  onClear
}: SeedKeywordFiltersProps) {
  // Initialize state with filter values
  const [localFilters, setLocalFilters] = useState({
    keyword: filters.keyword || '',
    excludeKeyword: filters.excludeKeyword || '',
    intent: filters.intent || '',
    minVolume: filters.minVolume?.toString() || '',
    maxVolume: filters.maxVolume?.toString() || '',
    minCpc: filters.minCpc?.toString() || '',
    maxCpc: filters.maxCpc?.toString() || '',
    minKd: filters.minKd?.toString() || '',
    maxKd: filters.maxKd?.toString() || ''
  });

  // Update local state when props change
  useEffect(() => {
    setLocalFilters({
      keyword: filters.keyword || '',
      excludeKeyword: filters.excludeKeyword || '',
      intent: filters.intent || '',
      minVolume: filters.minVolume?.toString() || '',
      maxVolume: filters.maxVolume?.toString() || '',
      minCpc: filters.minCpc?.toString() || '',
      maxCpc: filters.maxCpc?.toString() || '',
      minKd: filters.minKd?.toString() || '',
      maxKd: filters.maxKd?.toString() || ''
    });
  }, [filters]);

  const handleFilterChange = (field: string, value: string) => {
    const newFilters = { ...localFilters, [field]: value };
    setLocalFilters(newFilters);

    // Convert string values to numbers where appropriate
    onChange({
      keyword: newFilters.keyword || undefined,
      excludeKeyword: newFilters.excludeKeyword || undefined,
      intent: newFilters.intent || undefined,
      minVolume: newFilters.minVolume ? parseInt(newFilters.minVolume) : undefined,
      maxVolume: newFilters.maxVolume ? parseInt(newFilters.maxVolume) : undefined,
      minCpc: newFilters.minCpc ? parseFloat(newFilters.minCpc) : undefined,
      maxCpc: newFilters.maxCpc ? parseFloat(newFilters.maxCpc) : undefined,
      minKd: newFilters.minKd ? parseInt(newFilters.minKd) : undefined,
      maxKd: newFilters.maxKd ? parseInt(newFilters.maxKd) : undefined
    });
  };

  const handleClear = () => {
    const clearedFilters = {
      keyword: '',
      excludeKeyword: '',
      intent: '',
      minVolume: '',
      maxVolume: '',
      minCpc: '',
      maxCpc: '',
      minKd: '',
      maxKd: ''
    };
    setLocalFilters(clearedFilters);
    onClear();
  };

  const validateNumericInput = (value: string): boolean => {
    return value === '' || /^\d*$/.test(value);
  };

  const validateDecimalInput = (value: string): boolean => {
    return value === '' || /^\d*\.?\d{0,2}$/.test(value);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow space-y-6">
      <div className="grid grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Keyword Contains
          </label>
          <input
            type="text"
            value={localFilters.keyword}
            onChange={(e) => handleFilterChange('keyword', e.target.value)}
            placeholder="Filter by keyword"
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Exclude Keywords
          </label>
          <input
            type="text"
            value={localFilters.excludeKeyword}
            onChange={(e) => handleFilterChange('excludeKeyword', e.target.value)}
            placeholder="Keywords to exclude"
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Search Intent
          </label>
          <select
            value={localFilters.intent}
            onChange={(e) => handleFilterChange('intent', e.target.value)}
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="">All Intents</option>
            <option value="informational">Informational</option>
            <option value="commercial">Commercial</option>
            <option value="navigational">Navigational</option>
            <option value="transactional">Transactional</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Search Volume
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="text"
              value={localFilters.minVolume}
              onChange={(e) => {
                if (validateNumericInput(e.target.value)) {
                  handleFilterChange('minVolume', e.target.value);
                }
              }}
              placeholder="Min"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <span>-</span>
            <input
              type="text"
              value={localFilters.maxVolume}
              onChange={(e) => {
                if (validateNumericInput(e.target.value)) {
                  handleFilterChange('maxVolume', e.target.value);
                }
              }}
              placeholder="Max"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            CPC (USD)
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="text"
              value={localFilters.minCpc}
              onChange={(e) => {
                if (validateDecimalInput(e.target.value)) {
                  handleFilterChange('minCpc', e.target.value);
                }
              }}
              placeholder="Min"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <span>-</span>
            <input
              type="text"
              value={localFilters.maxCpc}
              onChange={(e) => {
                if (validateDecimalInput(e.target.value)) {
                  handleFilterChange('maxCpc', e.target.value);
                }
              }}
              placeholder="Max"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Keyword Difficulty
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="text"
              value={localFilters.minKd}
              onChange={(e) => {
                if (validateNumericInput(e.target.value)) {
                  handleFilterChange('minKd', e.target.value);
                }
              }}
              placeholder="Min"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <span>-</span>
            <input
              type="text"
              value={localFilters.maxKd}
              onChange={(e) => {
                if (validateNumericInput(e.target.value)) {
                  handleFilterChange('maxKd', e.target.value);
                }
              }}
              placeholder="Max"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-end space-x-4">
        <button
          onClick={handleClear}
          className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900"
        >
          Clear Filters
        </button>
        <button
          onClick={onApply}
          className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Apply Filters
        </button>
      </div>
    </div>
  );
}
