import React, { useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';

interface DomainFiltersProps {
  filters: {
    intent?: string;
    minPosition?: number;
    maxPosition?: number;
    minVolume?: number;
    maxVolume?: number;
    urlPattern?: string;
    minTraffic?: number;
    maxTraffic?: number;
    minCpc?: number;
    maxCpc?: number;
    ignoreSynonyms?: boolean;
    onlyFeaturedSnippets?: boolean;
  };
  onChange: (filters: any) => void;
  onApply: () => void;
  onClear: () => void;
}

export function DomainFilters({ filters, onChange, onApply, onClear }: DomainFiltersProps) {
  const [intent, setIntent] = useState(filters.intent || '');
  const [minPosition, setMinPosition] = useState(filters.minPosition?.toString() || '');
  const [maxPosition, setMaxPosition] = useState(filters.maxPosition?.toString() || '');
  const [minVolume, setMinVolume] = useState(filters.minVolume?.toString() || '');
  const [maxVolume, setMaxVolume] = useState(filters.maxVolume?.toString() || '');
  const [urlPattern, setUrlPattern] = useState(filters.urlPattern || '');
  const [minTraffic, setMinTraffic] = useState(filters.minTraffic?.toString() || '');
  const [maxTraffic, setMaxTraffic] = useState(filters.maxTraffic?.toString() || '');
  const [minCpc, setMinCpc] = useState(filters.minCpc?.toString() || '');
  const [maxCpc, setMaxCpc] = useState(filters.maxCpc?.toString() || '');
  const [ignoreSynonyms, setIgnoreSynonyms] = useState(filters.ignoreSynonyms || false);
  const [onlyFeaturedSnippets, setOnlyFeaturedSnippets] = useState(filters.onlyFeaturedSnippets || false);

  const handleFilterChange = () => {
    onChange({
      intent: intent || undefined,
      minPosition: minPosition ? parseInt(minPosition) : undefined,
      maxPosition: maxPosition ? parseInt(maxPosition) : undefined,
      minVolume: minVolume ? parseInt(minVolume) : undefined,
      maxVolume: maxVolume ? parseInt(maxVolume) : undefined,
      urlPattern: urlPattern || undefined,
      minTraffic: minTraffic ? parseInt(minTraffic) : undefined,
      maxTraffic: maxTraffic ? parseInt(maxTraffic) : undefined,
      minCpc: minCpc ? parseFloat(minCpc) : undefined,
      maxCpc: maxCpc ? parseFloat(maxCpc) : undefined,
      ignoreSynonyms,
      onlyFeaturedSnippets
    });
  };

  const handleClear = () => {
    setIntent('');
    setMinPosition('');
    setMaxPosition('');
    setMinVolume('');
    setMaxVolume('');
    setUrlPattern('');
    setMinTraffic('');
    setMaxTraffic('');
    setMinCpc('');
    setMaxCpc('');
    setIgnoreSynonyms(false);
    setOnlyFeaturedSnippets(false);
    onClear();
  };

  useEffect(() => {
    handleFilterChange();
  }, [
    intent,
    minPosition,
    maxPosition,
    minVolume,
    maxVolume,
    urlPattern,
    minTraffic,
    maxTraffic,
    minCpc,
    maxCpc,
    ignoreSynonyms,
    onlyFeaturedSnippets
  ]);

  return (
    <div className="bg-white p-6 rounded-lg shadow space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-6">
          <div className="flex items-center space-x-2">
            <Switch
              checked={ignoreSynonyms}
              onChange={setIgnoreSynonyms}
              className={`${
                ignoreSynonyms ? 'bg-blue-600' : 'bg-gray-200'
              } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
            >
              <span
                className={`${
                  ignoreSynonyms ? 'translate-x-6' : 'translate-x-1'
                } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
              />
            </Switch>
            <span className="text-sm text-gray-700">Ignore Synonyms</span>
          </div>

          <div className="flex items-center space-x-2">
            <Switch
              checked={onlyFeaturedSnippets}
              onChange={setOnlyFeaturedSnippets}
              className={`${
                onlyFeaturedSnippets ? 'bg-blue-600' : 'bg-gray-200'
              } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
            >
              <span
                className={`${
                  onlyFeaturedSnippets ? 'translate-x-6' : 'translate-x-1'
                } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
              />
            </Switch>
            <span className="text-sm text-gray-700">Featured Snippets Only</span>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Search Intent
          </label>
          <select
            value={intent}
            onChange={(e) => setIntent(e.target.value)}
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="">All Intents</option>
            <option value="informational">Informational</option>
            <option value="commercial">Commercial</option>
            <option value="navigational">Navigational</option>
            <option value="transactional">Transactional</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Position Range
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="number"
              value={minPosition}
              onChange={(e) => setMinPosition(e.target.value)}
              placeholder="Min"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <span>-</span>
            <input
              type="number"
              value={maxPosition}
              onChange={(e) => setMaxPosition(e.target.value)}
              placeholder="Max"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Search Volume
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="number"
              value={minVolume}
              onChange={(e) => setMinVolume(e.target.value)}
              placeholder="Min"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <span>-</span>
            <input
              type="number"
              value={maxVolume}
              onChange={(e) => setMaxVolume(e.target.value)}
              placeholder="Max"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            URL Pattern
          </label>
          <input
            type="text"
            value={urlPattern}
            onChange={(e) => setUrlPattern(e.target.value)}
            placeholder="Filter by URL"
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Traffic Range
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="number"
              value={minTraffic}
              onChange={(e) => setMinTraffic(e.target.value)}
              placeholder="Min"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <span>-</span>
            <input
              type="number"
              value={maxTraffic}
              onChange={(e) => setMaxTraffic(e.target.value)}
              placeholder="Max"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            CPC Range (USD)
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="number"
              step="0.01"
              value={minCpc}
              onChange={(e) => setMinCpc(e.target.value)}
              placeholder="Min"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <span>-</span>
            <input
              type="number"
              step="0.01"
              value={maxCpc}
              onChange={(e) => setMaxCpc(e.target.value)}
              placeholder="Max"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-end space-x-4">
        <button
          onClick={handleClear}
          className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900"
        >
          Clear Filters
        </button>
        <button
          onClick={onApply}
          className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Apply Filters
        </button>
      </div>
    </div>
  );
}
