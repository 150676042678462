import React, { useState, useEffect } from 'react';
import { Share2, Copy, Check, Globe } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { createShareLink } from '../../services/domainHistory';
import { getShareUrl, getAppUrl } from '../../utils/url';
import { getCustomDomain } from '../../services/settings';

interface ShareButtonProps {
  historyId: string;
}

export function ShareButton({ historyId }: ShareButtonProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const [copied, setCopied] = useState(false);
  const [customDomain, setCustomDomain] = useState<string | null>(null);
  const [selectedDomain, setSelectedDomain] = useState<'default' | 'custom'>('default');

  useEffect(() => {
    loadCustomDomain();
  }, []);

  const loadCustomDomain = async () => {
    try {
      const domain = await getCustomDomain();
      setCustomDomain(domain);
    } catch (error) {
      console.error('Error loading custom domain:', error);
    }
  };

  const handleShare = async () => {
    setIsLoading(true);
    try {
      const token = await createShareLink(historyId);
      const url = selectedDomain === 'custom' && customDomain
        ? `https://${customDomain}/shared/${token}`
        : `${getAppUrl()}/shared/${token}`;
      setShareUrl(url);
      setShowDialog(true);
    } catch (error) {
      console.error('Error creating share link:', error);
      toast.error('Failed to create share link');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
      toast.success('Link copied to clipboard');
    } catch (error) {
      toast.error('Failed to copy link');
    }
  };

  const handleDomainChange = async (domain: 'default' | 'custom') => {
    setSelectedDomain(domain);
    if (shareUrl) {
      const token = shareUrl.split('/').pop();
      if (token) {
        const newUrl = domain === 'custom' && customDomain
          ? `https://${customDomain}/shared/${token}`
          : `${getAppUrl()}/shared/${token}`;
        setShareUrl(newUrl);
      }
    }
  };

  return (
    <>
      <button
        onClick={handleShare}
        disabled={isLoading}
        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        <Share2 className="h-4 w-4 mr-2" />
        Share
      </button>

      {showDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-md w-full p-6">
            <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-4">
              Share Analysis
            </h3>
            
            {customDomain && (
              <div className="mb-4">
                <label className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 block">
                  Share using:
                </label>
                <div className="flex space-x-4">
                  <button
                    onClick={() => handleDomainChange('default')}
                    className={`px-4 py-2 rounded-lg text-sm font-medium ${
                      selectedDomain === 'default'
                        ? 'bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-200'
                        : 'bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-300'
                    }`}
                  >
                    Default Domain
                  </button>
                  <button
                    onClick={() => handleDomainChange('custom')}
                    className={`px-4 py-2 rounded-lg text-sm font-medium ${
                      selectedDomain === 'custom'
                        ? 'bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-200'
                        : 'bg-gray-100 text-gray-700 dark:bg-gray-700 dark:text-gray-300'
                    }`}
                  >
                    {customDomain}
                  </button>
                </div>
              </div>
            )}

            <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
              Anyone with this link can view the analysis results. The link will expire in 7 days.
            </p>

            <div className="flex items-center space-x-2 mb-6">
              <input
                type="text"
                value={shareUrl}
                readOnly
                className="flex-1 p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:text-gray-100"
              />
              <button
                onClick={handleCopy}
                className="inline-flex items-center px-3 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                {copied ? (
                  <Check className="h-4 w-4 text-green-500" />
                ) : (
                  <Copy className="h-4 w-4" />
                )}
              </button>
            </div>

            <div className="flex justify-end">
              <button
                onClick={() => setShowDialog(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-400"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
