import React, { useState } from 'react';
import { Search, ExternalLink, ChevronLeft, ChevronRight } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';

interface HistoricalTableProps {
  data: Array<{
    date: string;
    items: Array<{
      type: string;
      rank_group: number;
      rank_absolute: number;
      domain: string;
      url: string;
      title: string;
      description: string;
      etv: number;
      rank_info: {
        page_rank: number;
        main_domain_rank: number;
      };
      backlinks_info: {
        referring_domains: number;
        referring_main_domains: number;
        backlinks: number;
      };
    }>;
  }>;
  isLoading: boolean;
  error: string | null;
}

export function HistoricalTable({ data, isLoading, error }: HistoricalTableProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const [maxResults, setMaxResults] = useState(100);
  const [scrollPosition, setScrollPosition] = useState(0);

  const filterResults = (items: any[]) => {
    let filtered = items
      .filter(r => r.type === 'organic' || r.type === 'featured_snippet')
      .slice(0, maxResults);
    
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter(r => r.domain.toLowerCase().includes(term));
    }
    return filtered;
  };

  const handleAnalyzeMonth = (monthData: any) => {
    const currentParams = new URLSearchParams(location.search);
    const searchParams = new URLSearchParams({
      date: monthData.date,
      keyword: currentParams.get('keyword') || '',
      location: currentParams.get('location') || '',
      language: currentParams.get('language') || '',
      monthData: JSON.stringify(monthData)
    });
    navigate(`/serp-checker/analysis?${searchParams.toString()}`);
  };

  const handleScroll = (direction: 'left' | 'right') => {
    const container = document.getElementById('serp-tables-container');
    if (container) {
      const scrollAmount = direction === 'left' ? -400 : 400;
      const newPosition = scrollPosition + scrollAmount;
      container.scrollTo({ left: newPosition, behavior: 'smooth' });
      setScrollPosition(newPosition);
    }
  };

  if (isLoading) {
    return (
      <div className="bg-white p-6 rounded-lg shadow">
        <div className="animate-pulse space-y-4">
          <div className="h-4 bg-gray-200 rounded w-1/4"></div>
          <div className="space-y-3">
            {[...Array(10)].map((_, index) => (
              <div key={index} className="h-4 bg-gray-200 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white p-6 rounded-lg shadow">
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex flex-wrap gap-4 items-center">
        <div className="flex space-x-2">
          {[100, 50, 30, 20, 10].map(num => (
            <button
              key={num}
              onClick={() => setMaxResults(num)}
              className={`px-4 py-2 text-sm font-medium rounded-lg ${
                maxResults === num
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              Top {num}
            </button>
          ))}
        </div>

        <div className="flex-grow">
          <div className="relative">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search by domain"
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
          </div>
        </div>
      </div>

      <div className="relative">
        <button
          onClick={() => handleScroll('left')}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-white/90 p-2 rounded-full shadow-lg hover:bg-gray-100"
          aria-label="Scroll left"
        >
          <ChevronLeft className="w-6 h-6" />
        </button>

        <button
          onClick={() => handleScroll('right')}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-white/90 p-2 rounded-full shadow-lg hover:bg-gray-100"
          aria-label="Scroll right"
        >
          <ChevronRight className="w-6 h-6" />
        </button>

        <div 
          id="serp-tables-container"
          className="flex overflow-x-auto space-x-6 pb-4 hide-scrollbar"
          style={{ scrollBehavior: 'smooth' }}
        >
          {data.map((monthData, monthIndex) => {
            const date = new Date(monthData.date);
            const filteredItems = filterResults(monthData.items);
            
            return (
              <div 
                key={monthIndex} 
                className="bg-white rounded-lg shadow flex-none w-[400px]"
              >
                <div className="px-4 py-3 border-b border-gray-200 flex justify-between items-center">
                  <h3 className="font-semibold text-gray-900">
                    {date.toLocaleDateString('default', {
                      month: 'long',
                      year: 'numeric'
                    })}
                  </h3>
                  <button
                    onClick={() => handleAnalyzeMonth(monthData)}
                    className="p-2 text-blue-600 hover:text-blue-800 rounded-lg hover:bg-blue-50"
                    title="Analyze SERP data"
                  >
                    <ExternalLink className="w-4 h-4" />
                  </button>
                </div>
                <div className="overflow-hidden">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Rank
                        </th>
                        <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Domain
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {filteredItems.map((item, index) => (
                        <tr key={index} className="hover:bg-gray-50">
                          <td className="px-4 py-2 whitespace-nowrap text-sm">
                            <div className="flex items-center space-x-2">
                              <span className="text-gray-900">{item.rank_group}</span>
                              {item.type === 'featured_snippet' && (
                                <span className="px-1.5 py-0.5 text-xs bg-blue-100 text-blue-800 rounded">
                                  FS
                                </span>
                              )}
                            </div>
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap text-sm">
                            <a 
                              href={item.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-600 hover:text-blue-800"
                            >
                              {item.domain}
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
