import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getPublicDomainHistory } from '../services/domainHistory';
import { DomainOverview } from './DomainOverview';
import { SharedLayout } from '../components/shared/SharedLayout';
import { format } from 'date-fns';
import { formatNumber } from '../utils/format';

export function PublicDomainOverview() {
  const { domain } = useParams<{ domain: string }>();
  
  const { data: historyEntry, isLoading, error } = useQuery({
    queryKey: ['publicDomain', domain],
    queryFn: () => getPublicDomainHistory(domain!),
    enabled: !!domain,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 30 * 60 * 1000 // 30 minutes
  });

  React.useEffect(() => {
    if (historyEntry) {
      // Set page title
      const date = new Date(historyEntry.created_at);
      const formattedDate = format(date, 'MMMM yyyy');
      document.title = `${domain} Website Traffic, Ranking, Analytics [${formattedDate}] | Semdash`;

      // Set meta description
      const metaDescription = document.querySelector('meta[name="description"]');
      const description = `${domain} domain rank ${historyEntry.metrics.domainRank} with ${formatNumber(historyEntry.metrics.organicTraffic)} Traffic and ${formatNumber(historyEntry.metrics.backlinks)} Backlinks. Learn more about ${domain} top-ranking keywords, competitors, and best-performing pages.`;
      
      if (metaDescription) {
        metaDescription.setAttribute('content', description);
      } else {
        const meta = document.createElement('meta');
        meta.name = 'description';
        meta.content = description;
        document.head.appendChild(meta);
      }
    }
  }, [historyEntry, domain]);

  if (isLoading) {
    return (
      <SharedLayout>
        <div className="min-h-[80vh] flex items-center justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      </SharedLayout>
    );
  }

  if (error || !historyEntry) {
    return <Navigate to="https://seo.semdash.com/overview" replace />;
  }

  return (
    <SharedLayout>
      <div className="mb-4">
        <div className="text-sm text-gray-500 dark:text-gray-400">
          Analysis from {format(new Date(historyEntry.created_at), 'MMMM d, yyyy')}
        </div>
      </div>
      <DomainOverview sharedData={historyEntry} />
    </SharedLayout>
  );
}
