import React from 'react';
import { ChevronLeft, ChevronRight, Download, ArrowDown, ArrowUp } from 'lucide-react';
import { saveAs } from 'file-saver';
import { formatNumber } from '../../utils/format';
import { useNavigate } from 'react-router-dom';

interface ReferringDomainData {
  domain: string;
  rank: number;
  backlinks: number;
  brokenBacklinks: number;
  referringPages: number;
  brokenPages: number;
  spamScore: number;
  firstSeen: string;
  isNew: boolean;
  isLost: boolean;
  isBroken: boolean;
  dofollow: boolean;
}

interface SortConfig {
  field: 'rank' | 'backlinks' | 'broken_backlinks' | 'referring_pages' | 'broken_pages' | 'backlinks_spam_score' | 'first_seen';
  direction: 'asc' | 'desc';
}

interface ReferringDomainsTableProps {
  domains: ReferringDomainData[];
  totalCount: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  isLoading: boolean;
  error: string | null;
  currentTarget: string;
  sort: SortConfig;
  onSort: (field: SortConfig['field']) => void;
  itemsPerPage?: number;
}

export function ReferringDomainsTable({ 
  domains = [], 
  totalCount = 0,
  currentPage,
  onPageChange,
  isLoading,
  error,
  currentTarget,
  sort,
  onSort,
  itemsPerPage = 100
}: ReferringDomainsTableProps) {
  const navigate = useNavigate();
  const totalPages = Math.ceil(totalCount / itemsPerPage);

  const handleExport = () => {
    if (!domains.length) return;

    const headers = [
      'Domain',
      'Domain Rating',
      'Backlinks',
      'Broken Backlinks',
      'Referring Pages',
      'Broken Pages',
      'Spam Score',
      'First Seen',
      'Status'
    ];

    const csvData = domains.map(domain => [
      domain.domain,
      domain.rank,
      domain.backlinks,
      domain.brokenBacklinks,
      domain.referringPages,
      domain.brokenPages,
      domain.spamScore,
      new Date(domain.firstSeen).toLocaleDateString(),
      [
        domain.isNew ? 'New' : '',
        domain.isLost ? 'Lost' : '',
        domain.isBroken ? 'Broken' : '',
        !domain.dofollow ? 'NoFollow' : ''
      ].filter(Boolean).join(', ') || 'Active'
    ]);

    const csvContent = [
      headers.join(','),
      ...csvData.map(row => row.map(cell => 
        typeof cell === 'string' ? `"${cell.replace(/"/g, '""')}"` : cell
      ).join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'referring_domains_analysis.csv');
  };

  const handleRowClick = (domain: string) => {
    const initialFilter = {
      type: 'all',
      urlFrom: domain,
      urlTo: ''
    };

    const searchParams = new URLSearchParams({
      target: currentTarget,
      includeSubdomains: 'true',
      initialFilter: JSON.stringify(initialFilter)
    });

    navigate(`/botox/results?${searchParams.toString()}`);
  };

  const SortIcon = ({ field }: { field: SortConfig['field'] }) => {
    if (sort.field !== field) {
      return null;
    }
    return sort.direction === 'desc' ? 
      <ArrowDown className="w-4 h-4 ml-1" /> : 
      <ArrowUp className="w-4 h-4 ml-1" />;
  };

  if (isLoading) {
    return (
      <div className="bg-white p-6 rounded-lg shadow">
        <div className="animate-pulse space-y-4">
          <div className="h-4 bg-gray-200 rounded w-1/4"></div>
          <div className="space-y-3">
            {[...Array(10)].map((_, index) => (
              <div key={index} className="h-4 bg-gray-200 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white p-6 rounded-lg shadow">
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold text-gray-900">
            Referring Domains ({formatNumber(totalCount)})
          </h2>
          <button
            onClick={handleExport}
            disabled={domains.length === 0}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <Download className="h-4 w-4 mr-2" />
            Export CSV
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Referring Domain
              </th>
              <th 
                scope="col" 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                onClick={() => onSort('rank')}
              >
                <div className="flex items-center">
                  DR
                  <SortIcon field="rank" />
                </div>
              </th>
              <th 
                scope="col" 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                onClick={() => onSort('backlinks')}
              >
                <div className="flex items-center">
                  Backlinks Count
                  <SortIcon field="backlinks" />
                </div>
              </th>
              <th 
                scope="col" 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                onClick={() => onSort('broken_backlinks')}
              >
                <div className="flex items-center">
                  Broken Backlinks
                  <SortIcon field="broken_backlinks" />
                </div>
              </th>
              <th 
                scope="col" 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                onClick={() => onSort('referring_pages')}
              >
                <div className="flex items-center">
                  Referring Pages
                  <SortIcon field="referring_pages" />
                </div>
              </th>
              <th 
                scope="col" 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                onClick={() => onSort('broken_pages')}
              >
                <div className="flex items-center">
                  Broken Pages
                  <SortIcon field="broken_pages" />
                </div>
              </th>
              <th 
                scope="col" 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                onClick={() => onSort('backlinks_spam_score')}
              >
                <div className="flex items-center">
                  Spam Score
                  <SortIcon field="backlinks_spam_score" />
                </div>
              </th>
              <th 
                scope="col" 
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                onClick={() => onSort('first_seen')}
              >
                <div className="flex items-center">
                  First Seen
                  <SortIcon field="first_seen" />
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {domains.map((domain, index) => (
              <tr 
                key={index} 
                className="hover:bg-gray-50 cursor-pointer"
                onClick={() => handleRowClick(domain.domain)}
              >
                <td className="px-6 py-4">
                  <div className="space-y-1">
                    <div className="text-sm font-medium text-gray-900">
                      {domain.domain}
                    </div>
                    <div className="flex flex-wrap gap-1">
                      {domain.isNew && (
                        <span className="px-2 py-0.5 text-xs font-medium bg-blue-600 text-white rounded">
                          New
                        </span>
                      )}
                      {domain.isLost && (
                        <span className="px-2 py-0.5 text-xs font-medium rounded" style={{ background: '#ED8B88', color: '#ef3535' }}>
                          Lost
                        </span>
                      )}
                      {domain.isBroken && (
                        <span className="px-2 py-0.5 text-xs font-medium bg-red-600 text-white rounded">
                          Broken
                        </span>
                      )}
                      {domain.dofollow === false && (
                        <span className="px-2 py-0.5 text-xs font-medium bg-amber-400 text-amber-900 rounded">
                          No Follow
                        </span>
                      )}
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {domain.rank}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {formatNumber(domain.backlinks)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {formatNumber(domain.brokenBacklinks)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {formatNumber(domain.referringPages)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {formatNumber(domain.brokenPages)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {domain.spamScore}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {new Date(domain.firstSeen).toLocaleDateString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {totalPages > 1 && (
        <div className="px-6 py-4 border-t border-gray-200 flex items-center justify-between">
          <div className="flex-1 flex justify-between sm:hidden">
            <button
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:bg-gray-100 disabled:text-gray-400"
            >
              Previous
            </button>
            <button
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:bg-gray-100 disabled:text-gray-400"
            >
              Next
            </button>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Showing page <span className="font-medium">{currentPage}</span> of{' '}
                <span className="font-medium">{totalPages}</span>
              </p>
            </div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
              <button
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-100 disabled:text-gray-400"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeft className="h-5 w-5" />
              </button>
              
              {[...Array(Math.min(5, totalPages))].map((_, idx) => {
                let pageNumber;
                if (totalPages <= 5) {
                  pageNumber = idx + 1;
                } else if (currentPage <= 3) {
                  pageNumber = idx + 1;
                } else if (currentPage >= totalPages - 2) {
                  pageNumber = totalPages - 4 + idx;
                } else {
                  pageNumber = currentPage - 2 + idx;
                }

                return (
                  <button
                    key={pageNumber}
                    onClick={() => onPageChange(pageNumber)}
                    className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${
                      currentPage === pageNumber
                        ? 'z-10 bg-blue-50 border-blue-500 text-blue-600'
                        : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                    }`}
                  >
                    {pageNumber}
                  </button>
                );
              })}

              <button
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-100 disabled:text-gray-400"
              >
                <span className="sr-only">Next</span>
                <ChevronRight className="h-5 w-5" />
              </button>
            </nav>
          </div>
        </div>
      )}
    </div>
  );
}
