import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CompetitorsFullTable } from '../../components/domain/CompetitorsFullTable';
import { CompetitivePositioningMap } from '../../components/domain/competitvebubblechart';
import { fetchCompetitors } from '../../services/domain';
import { toast } from 'react-hot-toast';
import { AlertCircle, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { locations } from '../../data/locations';
import { ErrorState } from '../../components/shared/ErrorState';

interface CompetitorData {
  domain: string;
  keywords: number;
  traffic: number;
  metrics: any;
}

export function CompetitorAnalysisResults() {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [competitors, setCompetitors] = useState<CompetitorData[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;

  const currentParams = {
    domain: searchParams.get('domain') || '',
    location: searchParams.get('location') || '',
    language: searchParams.get('language') || ''
  };

  const locationName = locations.find(loc => 
    loc.code === currentParams.location
  )?.name || currentParams.location;

  const languageName = locations.find(loc => 
    loc.code === currentParams.location
  )?.languages.find(lang => 
    lang.code === currentParams.language
  )?.name || currentParams.language;

  const fetchData = async (page: number = 1) => {
    if (!currentParams.domain || !currentParams.location || !currentParams.language) return;

    setIsLoading(true);
    setError(null);

    try {
      const offset = (page - 1) * itemsPerPage;
      const data = await fetchCompetitors(
        currentParams.domain,
        currentParams.location,
        currentParams.language,
        itemsPerPage,
        offset
      );
      
      setCompetitors(data.competitors);
      setTotalCount(data.totalCount);
      setCurrentPage(page);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to fetch competitors';
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1);
  }, [currentParams.domain, currentParams.location, currentParams.language]);

  return (
    <div className="space-y-6">
      <nav className="flex items-center text-sm text-gray-500 dark:text-gray-400">
        <Link to="/competitor-analysis" className="hover:text-gray-700 dark:hover:text-gray-300">
          Competitor Analysis
        </Link>
        <ChevronRight className="w-4 h-4 mx-2" />
        <span>Results</span>
      </nav>

      <div className="space-y-4">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
          Competitors: {currentParams.domain}
        </h1>
        <div className="flex items-center space-x-6 text-sm text-gray-600 dark:text-gray-400">
          <span>Database: {locationName}</span>
          <span>Language: {languageName}</span>
        </div>
      </div>

      {error ? (
        <ErrorState 
          title="No competitors found"
          message="We couldn't find any competitor data. This could be because:"
          suggestions={[
            'The domain is too new or has very few rankings',
            'There are no competing domains in this market',
            'The domain name was mistyped or does not exist'
          ]}
        />
      ) : (
        <>
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">
              Competitive Positioning Map
            </h2>
            <div className="h-[500px]">
              <CompetitivePositioningMap 
                data={competitors} 
                maxCompetitors={10} 
              />
            </div>
          </div>

          <CompetitorsFullTable 
            competitors={competitors}
            totalCount={totalCount}
            currentPage={currentPage}
            onPageChange={fetchData}
            isLoading={isLoading}
            currentParams={{
              location: currentParams.location,
              language: currentParams.language
            }}
          />
        </>
      )}
    </div>
  );
}
