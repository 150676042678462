import { api } from './api';

interface BacklinkFilter {
  type: 'all' | 'new' | 'broken' | 'live' | 'lost' | 'dofollow' | 'nofollow';
  urlFrom?: string;
  urlTo?: string;
}

interface SortConfig {
  field: 'domain_from_rank' | 'first_seen' | 'last_seen';
  direction: 'asc' | 'desc';
}

export async function fetchBotoxBacklinks(
  target: string,
  includeSubdomains: boolean,
  mode: 'as_is' | 'one_per_domain' | 'one_per_anchor' = 'as_is',
  filters?: BacklinkFilter,
  sort?: SortConfig,
  offset: number = 0
): Promise<{ backlinks: any[]; totalCount: number }> {
  try {
    // Clean domain name
    const cleanTarget = target.replace(/^(https?:\/\/)?(www\.)?/, '').replace(/\/$/, '');

    const payload = [{
      target: cleanTarget,
      limit: 100,
      offset: offset,
      internal_list_limit: 10,
      backlinks_status_type: 'all',
      include_subdomains: includeSubdomains,
      exclude_internal_backlinks: true,
      include_indirect_links: true,
      mode: mode
    }];

    // Add sorting if provided
    if (sort) {
      payload[0].order_by = [`${sort.field},${sort.direction}`];
    }

    // Add filters if provided
    if (filters) {
      const filterArray = [];

      switch (filters.type) {
        case 'new':
          filterArray.push(['is_new', '=', true]);
          break;
        case 'broken':
          filterArray.push(['is_broken', '=', true]);
          break;
        case 'live':
          filterArray.push(['is_lost', '=', false]);
          break;
        case 'lost':
          filterArray.push(['is_lost', '=', true]);
          break;
        case 'dofollow':
          filterArray.push(['dofollow', '=', true]);
          break;
        case 'nofollow':
          filterArray.push(['dofollow', '=', false]);
          break;
      }

      // Add URL filters
      if (filters.urlFrom && filters.urlTo) {
        filterArray.push(
          ['url_from', 'like', `%${filters.urlFrom}%`],
          'and',
          ['url_to', 'like', `%${filters.urlTo}%`]
        );
      } else {
        if (filters.urlFrom) {
          filterArray.push(['url_from', 'like', `%${filters.urlFrom}%`]);
        }
        if (filters.urlTo) {
          filterArray.push(['url_to', 'like', `%${filters.urlTo}%`]);
        }
      }

      if (filterArray.length > 0) {
        payload[0].filters = filterArray;
      }
    }

    const response = await api.post(
      '/backlinks/backlinks/live',
      payload
    );

    if (!response.data?.tasks?.[0]?.result?.[0]) {
      throw new Error('No data received from API');
    }

    const result = response.data.tasks[0].result[0];
    return {
      backlinks: result.items.map(item => ({
        type: item.type,
        domainFromRank: item.domain_from_rank,
        pageFromTitle: item.page_from_title,
        urlFrom: item.url_from,
        pageFromExternalLinks: item.page_from_external_links,
        pageFromInternalLinks: item.page_from_internal_links,
        anchor: item.anchor,
        urlTo: item.url_to,
        firstSeen: item.first_seen,
        lastSeen: item.last_seen,
        isNew: item.is_new,
        isLost: item.is_lost,
        isBroken: item.is_broken,
        dofollow: item.dofollow
      })),
      totalCount: result.total_count
    };
  } catch (error) {
    console.error('Botox API Error:', error);
    throw new Error(error instanceof Error ? error.message : 'Failed to fetch backlink data');
  }
}
