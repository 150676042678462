import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getKeywordsInList, deleteKeywordsFromList, ListKeyword } from '../../services/keywordLists';
import { toast } from 'react-hot-toast';
import { KeywordListHeader } from '../../components/keywords/KeywordListHeader';
import { KeywordListTable } from '../../components/keywords/KeywordListTable';

export function ListDetails() {
  const { id } = useParams<{ id: string }>();
  const [keywords, setKeywords] = useState<ListKeyword[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedKeywords, setSelectedKeywords] = useState<Set<string>>(new Set());

  useEffect(() => {
    if (id) loadKeywords();
  }, [id]);

  const loadKeywords = async () => {
    try {
      setIsLoading(true);
      const data = await getKeywordsInList(id!);
      setKeywords(data);
    } catch (error) {
      toast.error('Failed to load keywords');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    if (selectedKeywords.size === 0) return;
    if (!confirm(`Are you sure you want to delete ${selectedKeywords.size} keywords?`)) return;

    try {
      await deleteKeywordsFromList(id!, Array.from(selectedKeywords));
      toast.success('Keywords deleted successfully');
      loadKeywords();
      setSelectedKeywords(new Set());
    } catch (error) {
      toast.error('Failed to delete keywords');
    }
  };

  const handleExport = () => {
    const csvData = [
      ['Keyword', 'Search Volume', 'CPC', 'Competition', 'Difficulty', 'Intent', 'Source', 'Language', 'Location'],
      ...keywords.map(k => [
        k.keyword,
        k.search_volume,
        k.cpc,
        k.competition,
        k.keyword_difficulty,
        k.intent,
        k.source,
        k.language,
        k.location
      ])
    ];

    const csvContent = csvData.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'keywords.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (isLoading) {
    return (
      <div className="animate-pulse space-y-4">
        <div className="h-8 bg-gray-200 rounded w-1/4"></div>
        <div className="space-y-3">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="h-12 bg-gray-200 rounded"></div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <KeywordListHeader 
        selectedCount={selectedKeywords.size}
        onExport={handleExport}
        onDelete={handleDelete}
      />

      <KeywordListTable 
        keywords={keywords}
        selectedKeywords={selectedKeywords}
        onSelectionChange={setSelectedKeywords}
      />
    </div>
  );
}
