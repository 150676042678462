import React from 'react';
import { Check } from 'lucide-react';

interface PricingTableProps {
  userEmail?: string | null;
}

const plans = [
  {
    name: 'Gold',
    price: '$19',
    credits: '10,000',
    features: [
      '10,000 monthly credits',
      'Priority support',
      'Advanced analytics',
      'Team collaboration',
      'API access'
    ],
    link: 'https://buy.stripe.com/3csg2DdXeeKp7TOcMN',
    popular: false
  },
  {
    name: 'Diamond',
    price: '$99',
    credits: '100,000',
    features: [
      '100,000 monthly credits',
      'Premium support',
      'Custom reports',
      'White-label exports',
      'Dedicated account manager'
    ],
    link: 'https://buy.stripe.com/fZeaIj8CUdGl5LGaEG',
    popular: true
  },
  {
    name: 'Elite',
    price: '$299',
    credits: '500,000',
    features: [
      '500,000 monthly credits',
      '24/7 VIP support',
      'Custom integrations',
      'Enterprise features',
      'Unlimited team members'
    ],
    link: 'https://buy.stripe.com/8wM5nZbP69q55LG28b',
    popular: false
  }
];

export function PricingTable({ userEmail }: PricingTableProps) {
  const handleSubscribe = (plan: typeof plans[0]) => {
    const url = new URL(plan.link);
    if (userEmail) {
      url.searchParams.set('prefilled_email', userEmail);
    }
    window.open(url.toString(), '_blank');
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      {plans.map((plan) => (
        <div
          key={plan.name}
          className={`relative bg-white dark:bg-gray-800 rounded-2xl shadow-xl overflow-hidden ${
            plan.popular ? 'ring-2 ring-blue-500 scale-105 z-10' : ''
          }`}
        >
          {plan.popular && (
            <div className="absolute top-0 right-0 bg-blue-500 text-white px-4 py-1 rounded-bl-lg text-sm font-medium">
              Most Popular
            </div>
          )}
          <div className="p-8">
            <h3 className="text-xl font-bold text-gray-900 dark:text-gray-100">{plan.name}</h3>
            <div className="mt-4 flex items-baseline">
              <span className="text-4xl font-bold text-gray-900 dark:text-gray-100">{plan.price}</span>
              <span className="ml-2 text-gray-500 dark:text-gray-400">/month</span>
            </div>
            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
              {plan.credits} credits per month
            </p>

            <ul className="mt-6 space-y-4">
              {plan.features.map((feature) => (
                <li key={feature} className="flex items-start">
                  <Check className="h-5 w-5 text-green-500 shrink-0" />
                  <span className="ml-3 text-sm text-gray-700 dark:text-gray-300">{feature}</span>
                </li>
              ))}
            </ul>

            <button
              onClick={() => handleSubscribe(plan)}
              className={`mt-8 w-full py-3 px-4 rounded-lg text-sm font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                plan.popular
                  ? 'bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-500'
                  : 'bg-gray-100 text-gray-900 hover:bg-gray-200 focus:ring-gray-500 dark:bg-gray-700 dark:text-gray-100 dark:hover:bg-gray-600'
              }`}
            >
              Subscribe Now
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}
