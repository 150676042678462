import React from 'react';
import { Switch } from '@headlessui/react';

interface KeywordGapFiltersProps {
  filters: {
    intersections: boolean;
    ignoreSynonyms: boolean;
    intent: string;
    keyword: string;
    minKD?: number;
    maxKD?: number;
    minVolume?: number;
    maxVolume?: number;
  };
  onChange: (filters: any) => void;
  onApply: () => void;
  onClear: () => void;
}

export function KeywordGapFilters({ filters, onChange, onApply, onClear }: KeywordGapFiltersProps) {
  const handleIntersectionChange = (isShared: boolean) => {
    const newFilters = { ...filters, intersections: isShared };
    onChange(newFilters); // This will trigger immediate data fetch in parent
  };

  const handleFilterChange = (newFilters: typeof filters) => {
    onChange(newFilters); // This won't trigger immediate fetch - needs Apply button
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow space-y-6">
      <div className="flex items-center justify-between">
        <div className="space-x-4">
          <button
            onClick={() => handleIntersectionChange(false)}
            className={`px-4 py-2 rounded-lg text-sm font-medium ${
              !filters.intersections
                ? 'bg-blue-600 text-white'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            Missing
          </button>
          <button
            onClick={() => handleIntersectionChange(true)}
            className={`px-4 py-2 rounded-lg text-sm font-medium ${
              filters.intersections
                ? 'bg-blue-600 text-white'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            Shared
          </button>
        </div>

        <div className="flex items-center space-x-2">
          <span className="text-sm text-gray-700">Ignore Synonyms</span>
          <Switch
            checked={filters.ignoreSynonyms}
            onChange={(checked) => handleFilterChange({ ...filters, ignoreSynonyms: checked })}
            className={`${
              filters.ignoreSynonyms ? 'bg-blue-600' : 'bg-gray-200'
            } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
          >
            <span
              className={`${
                filters.ignoreSynonyms ? 'translate-x-6' : 'translate-x-1'
              } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
            />
          </Switch>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Search Intent
          </label>
          <select
            value={filters.intent}
            onChange={(e) => handleFilterChange({ ...filters, intent: e.target.value })}
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="">All Intents</option>
            <option value="informational">Informational</option>
            <option value="commercial">Commercial</option>
            <option value="navigational">Navigational</option>
            <option value="transactional">Transactional</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Keyword Filter
          </label>
          <input
            type="text"
            value={filters.keyword}
            onChange={(e) => handleFilterChange({ ...filters, keyword: e.target.value })}
            placeholder="Filter by keyword..."
            className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Keyword Difficulty
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="number"
              min="0"
              max="100"
              value={filters.minKD || ''}
              onChange={(e) => handleFilterChange({ ...filters, minKD: e.target.value ? parseInt(e.target.value) : undefined })}
              placeholder="Min"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <span>-</span>
            <input
              type="number"
              min="0"
              max="100"
              value={filters.maxKD || ''}
              onChange={(e) => handleFilterChange({ ...filters, maxKD: e.target.value ? parseInt(e.target.value) : undefined })}
              placeholder="Max"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Search Volume
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="number"
              min="0"
              value={filters.minVolume || ''}
              onChange={(e) => handleFilterChange({ ...filters, minVolume: e.target.value ? parseInt(e.target.value) : undefined })}
              placeholder="Min"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <span>-</span>
            <input
              type="number"
              min="0"
              value={filters.maxVolume || ''}
              onChange={(e) => handleFilterChange({ ...filters, maxVolume: e.target.value ? parseInt(e.target.value) : undefined })}
              placeholder="Max"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-end space-x-4">
        <button
          onClick={onClear}
          className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900"
        >
          Clear Filters
        </button>
        <button
          onClick={onApply}
          className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Apply Filters
        </button>
      </div>
    </div>
  );
}
