import React from 'react';
import { formatNumber } from '../../utils/format';

interface MetricsBoxesProps {
  metrics: {
    domainRank: number;
    organicTraffic: number;
    keywords: number;
    backlinks: number;
    referringDomains: number;
    brokenPages: number;
    brokenBacklinks: number;
    ips: number;
    subnets: number;
  };
}

const tooltips = {
  domainRank: "Domain Rank indicates the overall authority of your domain based on backlink quality and quantity",
  organicTraffic: "Estimated monthly organic traffic from search engines",
  keywords: "Total number of keywords your domain ranks for in Google's top 100 results",
  backlinks: "Total number of backlinks pointing to your domain",
  referringDomains: "Number of unique domains linking to your website",
  brokenPages: "Number of pages returning 4xx or 5xx status codes",
  brokenBacklinks: "Number of backlinks pointing to non-existent pages",
  ips: "Number of unique IP addresses hosting backlinks to your domain",
  subnets: "Number of unique subnets containing backlinks to your domain"
};

export function MetricsBoxes({ metrics }: MetricsBoxesProps) {
  const boxes = [
    {
      label: 'Domain Rank',
      value: metrics.domainRank,
      tooltip: tooltips.domainRank
    },
    {
      label: 'Organic Traffic',
      value: formatNumber(metrics.organicTraffic),
      tooltip: tooltips.organicTraffic
    },
    {
      label: 'Keywords',
      value: formatNumber(metrics.keywords),
      tooltip: tooltips.keywords
    },
    {
      label: 'Backlinks',
      value: formatNumber(metrics.backlinks),
      tooltip: tooltips.backlinks
    },
    {
      label: 'Referring Domains',
      value: formatNumber(metrics.referringDomains),
      tooltip: tooltips.referringDomains
    },
    {
      label: 'Broken Pages',
      value: formatNumber(metrics.brokenPages),
      tooltip: tooltips.brokenPages
    },
    {
      label: 'Broken Backlinks',
      value: formatNumber(metrics.brokenBacklinks),
      tooltip: tooltips.brokenBacklinks
    },
    {
      label: 'IPs',
      value: formatNumber(metrics.ips),
      tooltip: tooltips.ips
    },
    {
      label: 'Subnets',
      value: formatNumber(metrics.subnets),
      tooltip: tooltips.subnets
    }
  ];

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="grid grid-cols-9 divide-x">
        {boxes.map((box, index) => (
          <div 
            key={index}
            className="px-4 py-3 tooltip-wrap"
          >
            <div className="h-full flex flex-col justify-between min-h-[70px]">
              <p className="text-sm text-gray-600">{box.label}</p>
              <p className="text-lg font-semibold text-blue-500 mt-auto">
                {box.value}
              </p>
            </div>
            
            {/* Custom tooltip */}
            <div className="tooltip-text">
              {box.tooltip}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}