import React from 'react';
import { Search } from 'lucide-react';

interface DomainAnalysisButtonProps {
  onClick: () => void;
  isLoading: boolean;
}

export function DomainAnalysisButton({ onClick, isLoading }: DomainAnalysisButtonProps) {
  return (
    <button
      onClick={onClick}
      disabled={isLoading}
      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
    >
      <Search className="w-4 h-4 mr-2" />
      {isLoading ? 'Analyzing...' : 'New Analysis'}
    </button>
  );
}
