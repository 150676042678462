import React, { useState } from 'react';
import { DomainKeyword } from '../../types';
import { ArrowUpRight, ArrowDownRight, Minus, Download, ChevronLeft, ChevronRight } from 'lucide-react';
import { KeywordChart } from './KeywordChart';

interface KeywordsTableProps {
  keywords: DomainKeyword[];
  isLoading: boolean;
  onKeywordSelect?: (keyword: DomainKeyword) => void;
  totalCount?: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  itemsPerPage?: number;
}

export function KeywordsTable({ 
  keywords = [], 
  isLoading, 
  totalCount = 0,
  currentPage,
  onPageChange,
  itemsPerPage = 100
}: KeywordsTableProps) {
  const [selectedKeywordId, setSelectedKeywordId] = useState<string | null>(null);

  const totalPages = Math.ceil(totalCount / itemsPerPage);

  const handleExport = () => {
    if (!keywords.length) return;

    const csvContent = [
      ['Keyword', 'Search Volume', 'Traffic', 'CPC', 'Position', 'Change', 'Intent', 'URL', 'Last Updated'],
      ...keywords.map(kw => [
        kw.keyword,
        kw.searchVolume,
        kw.etv,
        kw.cpc || 0,
        kw.rankAbsolute,
        kw.previousRankAbsolute ? kw.previousRankAbsolute - kw.rankAbsolute : 0,
        kw.intent,
        kw.relativeUrl,
        kw.lastUpdatedTime
      ])
    ].map(row => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'keyword-data.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleRowClick = (keyword: DomainKeyword) => {
    const keywordId = keyword.keyword;
    setSelectedKeywordId(selectedKeywordId === keywordId ? null : keywordId);
  };

  if (isLoading) {
    return (
      <div className="mt-6 bg-white p-6 rounded-lg shadow">
        <div className="animate-pulse space-y-4">
          <div className="h-4 bg-gray-200 rounded w-1/4"></div>
          <div className="space-y-3">
            {[...Array(5)].map((_, index) => (
              <div key={index} className="h-4 bg-gray-200 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (!keywords || keywords.length === 0) {
    return (
      <div className="mt-6 bg-white p-6 rounded-lg shadow">
        <p className="text-gray-500 text-center">No keywords found</p>
      </div>
    );
  }

  return (
    <div className="mt-6 bg-white rounded-lg shadow overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
        <div className="text-lg font-semibold">
          Total Keywords: <span className="text-blue-600">{totalCount.toLocaleString()}</span>
        </div>
        <button
          onClick={handleExport}
          className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <Download className="h-4 w-4 mr-2" />
          Export CSV
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Keyword</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Search Volume</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Traffic</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">CPC</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Position</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Change</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Intent</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">URL</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Updated</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {keywords.map((keyword) => (
              <React.Fragment key={keyword.keyword}>
                <tr 
                  onClick={() => handleRowClick(keyword)}
                  className="hover:bg-gray-50 cursor-pointer"
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{keyword.keyword}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{keyword.searchVolume.toLocaleString()}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{Math.round(keyword.etv).toLocaleString()}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {keyword.cpc ? `$${keyword.cpc.toFixed(2)}` : '-'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    <div className="flex items-center">
                      {keyword.rankAbsolute}
                      {keyword.isFeaturedSnippet && (
                        <img 
                          src="https://seoweb.niletouch.org/dist/img/svgexport-58.svg" 
                          alt="Featured Snippet" 
                          className="w-4 h-4 ml-1"
                        />
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {keyword.previousRankAbsolute ? (
                      <div className={`flex items-center ${
                        keyword.previousRankAbsolute - keyword.rankAbsolute > 0 
                          ? 'text-green-600' 
                          : keyword.previousRankAbsolute - keyword.rankAbsolute < 0 
                            ? 'text-red-600' 
                            : 'text-gray-600'
                      }`}>
                        {keyword.previousRankAbsolute - keyword.rankAbsolute > 0 ? (
                          <ArrowUpRight className="w-4 h-4 mr-1" />
                        ) : keyword.previousRankAbsolute - keyword.rankAbsolute < 0 ? (
                          <ArrowDownRight className="w-4 h-4 mr-1" />
                        ) : (
                          <Minus className="w-4 h-4 mr-1" />
                        )}
                        {Math.abs(keyword.previousRankAbsolute - keyword.rankAbsolute)}
                      </div>
                    ) : '-'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 capitalize">{keyword.intent}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{keyword.relativeUrl}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {new Date(keyword.lastUpdatedTime).toLocaleDateString()}
                  </td>
                </tr>
                {selectedKeywordId === keyword.keyword && (
                  <tr>
                    <td colSpan={9} className="px-6 py-4">
                      <KeywordChart keyword={keyword} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {totalPages > 1 && (
        <div className="px-6 py-4 border-t border-gray-200 flex items-center justify-between">
          <div className="flex-1 flex justify-between sm:hidden">
            <button
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:bg-gray-100 disabled:text-gray-400"
            >
              Previous
            </button>
            <button
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:bg-gray-100 disabled:text-gray-400"
            >
              Next
            </button>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Showing page <span className="font-medium">{currentPage}</span> of{' '}
                <span className="font-medium">{totalPages}</span>
              </p>
            </div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <button
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-100 disabled:text-gray-400"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeft className="h-5 w-5" />
              </button>
              
              {[...Array(Math.min(5, totalPages))].map((_, idx) => {
                let pageNumber;
                if (totalPages <= 5) {
                  pageNumber = idx + 1;
                } else if (currentPage <= 3) {
                  pageNumber = idx + 1;
                } else if (currentPage >= totalPages - 2) {
                  pageNumber = totalPages - 4 + idx;
                } else {
                  pageNumber = currentPage - 2 + idx;
                }

                return (
                  <button
                    key={pageNumber}
                    onClick={() => onPageChange(pageNumber)}
                    className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${
                      currentPage === pageNumber
                        ? 'z-10 bg-blue-50 border-blue-500 text-blue-600'
                        : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                    }`}
                  >
                    {pageNumber}
                  </button>
                );
              })}

              <button
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-100 disabled:text-gray-400"
              >
                <span className="sr-only">Next</span>
                <ChevronRight className="h-5 w-5" />
              </button>
            </nav>
          </div>
        </div>
      )}
    </div>
  );
}
