import { api } from './api';

interface SeedKeywordFilter {
  keyword?: string;
  excludeKeyword?: string;
  intent?: string;
  minVolume?: number;
  maxVolume?: number;
  minCpc?: number;
  maxCpc?: number;
  minKd?: number;
  maxKd?: number;
}

export async function fetchSeedKeywords(
  keyword: string,
  locationCode: string,
  languageCode: string,
  filters?: SeedKeywordFilter,
  offset: number = 0
) {
  try {
    const payload = [{
      keyword,
      location_code: parseInt(locationCode),
      language_code: languageCode,
      include_seed_keyword: true,
      include_serp_info: true,
      ignore_synonyms: true,
      include_clickstream_data: false,
      exact_match: false,
      limit: 100,
      offset: offset
    }];

    // Add filters if provided
    if (filters) {
      const filterArray = [];

      // Add keyword filter
      if (filters.keyword) {
        filterArray.push(["keyword", "regex", filters.keyword]);
      }

      // Add exclude keyword filter
      if (filters.excludeKeyword) {
        if (filterArray.length > 0) filterArray.push("and");
        filterArray.push(["keyword", "not_regex", filters.excludeKeyword]);
      }

      // Add volume filter
      if (filters.minVolume !== undefined || filters.maxVolume !== undefined) {
        if (filterArray.length > 0) filterArray.push("and");
        
        if (filters.minVolume !== undefined && filters.maxVolume !== undefined) {
          filterArray.push(
            ["keyword_info.search_volume", ">=", filters.minVolume],
            "and",
            ["keyword_info.search_volume", "<=", filters.maxVolume]
          );
        } else if (filters.minVolume !== undefined) {
          filterArray.push(["keyword_info.search_volume", ">=", filters.minVolume]);
        } else if (filters.maxVolume !== undefined) {
          filterArray.push(["keyword_info.search_volume", "<=", filters.maxVolume]);
        }
      }

      // Add CPC filter
      if (filters.minCpc !== undefined || filters.maxCpc !== undefined) {
        if (filterArray.length > 0) filterArray.push("and");
        
        if (filters.minCpc !== undefined && filters.maxCpc !== undefined) {
          filterArray.push(
            ["keyword_info.cpc", ">=", filters.minCpc],
            "and",
            ["keyword_info.cpc", "<=", filters.maxCpc]
          );
        } else if (filters.minCpc !== undefined) {
          filterArray.push(["keyword_info.cpc", ">=", filters.minCpc]);
        } else if (filters.maxCpc !== undefined) {
          filterArray.push(["keyword_info.cpc", "<=", filters.maxCpc]);
        }
      }

      // Add Keyword Difficulty filter
      if (filters.minKd !== undefined || filters.maxKd !== undefined) {
        if (filterArray.length > 0) filterArray.push("and");
        
        if (filters.minKd !== undefined && filters.maxKd !== undefined) {
          filterArray.push(
            ["keyword_properties.keyword_difficulty", ">=", filters.minKd],
            "and",
            ["keyword_properties.keyword_difficulty", "<=", filters.maxKd]
          );
        } else if (filters.minKd !== undefined) {
          filterArray.push(["keyword_properties.keyword_difficulty", ">=", filters.minKd]);
        } else if (filters.maxKd !== undefined) {
          filterArray.push(["keyword_properties.keyword_difficulty", "<=", filters.maxKd]);
        }
      }

      // Add intent filter
      if (filters.intent) {
        if (filterArray.length > 0) filterArray.push("and");
        filterArray.push(["search_intent_info.main_intent", "=", filters.intent]);
      }

      if (filterArray.length > 0) {
        payload[0].filters = filterArray;
      }
    }

    console.log('API Payload:', JSON.stringify(payload, null, 2));

    const response = await api.post(
      '/dataforseo_labs/google/keyword_suggestions/live',
      payload
    );

    if (!response.data?.tasks?.[0]?.result?.[0]) {
      throw new Error('No data received from API');
    }

    const result = response.data.tasks[0].result[0];
    const keywords = [];

    // Add seed keyword if available
    if (result.seed_keyword_data) {
      keywords.push({
        keyword: result.seed_keyword,
        searchVolume: result.seed_keyword_data.keyword_info.search_volume,
        cpc: result.seed_keyword_data.keyword_info.cpc,
        competition: (result.seed_keyword_data.keyword_info.competition || 0) * 100,
        keywordDifficulty: result.seed_keyword_data.keyword_properties.keyword_difficulty,
        monthlySearches: result.seed_keyword_data.keyword_info.monthly_searches,
        backlinks: result.seed_keyword_data.avg_backlinks_info?.backlinks || 0,
        referringDomains: result.seed_keyword_data.avg_backlinks_info?.referring_main_domains || 0,
        domainRank: result.seed_keyword_data.avg_backlinks_info?.main_domain_rank || 0,
        intent: result.seed_keyword_data.search_intent_info?.main_intent || 'Unknown'
      });
    }

    // Add suggested keywords
    if (result.items) {
      keywords.push(...result.items.map(item => ({
        keyword: item.keyword,
        searchVolume: item.keyword_info.search_volume,
        cpc: item.keyword_info.cpc,
        competition: (item.keyword_info.competition || 0) * 100,
        keywordDifficulty: item.keyword_properties.keyword_difficulty,
        monthlySearches: item.keyword_info.monthly_searches,
        backlinks: item.avg_backlinks_info?.backlinks || 0,
        referringDomains: item.avg_backlinks_info?.referring_main_domains || 0,
        domainRank: item.avg_backlinks_info?.main_domain_rank || 0,
        intent: item.search_intent_info?.main_intent || 'Unknown'
      })));
    }

    return {
      keywords,
      totalCount: result.total_count || 0
    };
  } catch (error) {
    console.error('Seed Keywords API Error:', error);
    if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error('Failed to fetch seed keywords');
  }
}
