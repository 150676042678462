import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { AuthProvider } from './contexts/AuthContext';
import { CreditsProvider } from './contexts/CreditsContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { ProtectedRoute } from './components/ProtectedRoute';
import { Layout } from './components/Layout';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NotFound } from './pages/NotFound';
import { TableStyles } from './components/shared/TableStyles';

// Authentication & Account
import { Login } from './pages/Login';
import { Signup } from './pages/Signup';
import { Settings } from './pages/Settings';
import { InviteAccept } from './pages/InviteAccept';
import { Subscription } from './pages/Subscription';

// Domain Analysis
import { DomainSearch } from './pages/DomainSearch';
import { DomainOverview } from './pages/DomainOverview';
import { BacklinkAnalysis } from './pages/BacklinkAnalysis';
import { DomainAnalysis } from './pages/DomainAnalysis';
import { CompetitorDomains } from './pages/CompetitorDomains';
import { ReferringDomains } from './pages/ReferringDomains';
import { ReferringDomainsResults } from './pages/ReferringDomains/Results';
import { BacklinkGap } from './pages/BacklinkGap';
import { BacklinkGapResults } from './pages/BacklinkGap/Results';
import { SharedDomainOverview } from './pages/SharedDomainOverview';
import { PublicDomainOverview } from './pages/PublicDomainOverview';
import { CompetitorAnalysisSearch, CompetitorAnalysisResults } from './pages/CompetitorAnalysis';

// Keyword Research
import { SeedKeywordSearch } from './pages/SeedKeywordSearch';
import { SeedKeywordResults } from './pages/SeedKeywordResults';
import { KeywordLists } from './pages/KeywordLists';
import { ListDetails } from './pages/KeywordLists/ListDetails';
import { KeywordGap } from './pages/KeywordGap';
import { KeywordGapResults } from './pages/KeywordGap/Results';
import { KeywordOverview } from './pages/KeywordOverview';
import { KeywordOverviewResults } from './pages/KeywordOverview/Results';
import { RankedKeywords } from './pages/RankedKeywords';
import { RankedKeywordsResults } from './pages/RankedKeywords/Results';

// SERP Analysis
import { SerpKeywordSearch } from './pages/SerpKeywordSearch';
import { SerpKeywordResults } from './pages/SerpKeywordResults';
import { SerpChecker } from './pages/SerpChecker';
import { SerpCheckerResults } from './pages/SerpChecker/Results';
import { SerpAnalysis } from './pages/SerpAnalysis';

// Pages Analysis
import { TopPagesSearch } from './pages/TopPagesSearch';
import { TopPagesResults } from './pages/TopPagesResults';
import { PagesByLinks } from './pages/PagesByLinks';
import { PagesByLinksResults } from './pages/PagesByLinks/Results';

// Miscellaneous
import { Botox } from './pages/Botox';
import { BotoxResults } from './pages/Botox/Results';
import { TrafficShare } from './pages/TrafficShare';
import { TrafficShareResults } from './pages/TrafficShare/Results';
import { PPA } from './pages/PPA';
import { PPAResults } from './pages/PPA/Results';
import { Performance } from './pages/Performance';
import { PerformanceResults } from './pages/Performance/Results';

// Create Query Client
const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ThemeProvider>
          <Router>
            <CreditsProvider>
              <TableStyles />
              <Routes>
                {/* Authentication Routes */}
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/invite" element={<InviteAccept />} />
                
                {/* Public Routes */}
                <Route path="/shared/:token" element={<SharedDomainOverview />} />
                <Route path="/website-overview/:domain" element={<PublicDomainOverview />} />
                
                {/* Default Redirect */}
                <Route path="/" element={<Navigate to="/overview" replace />} />
                
                <Route element={<Layout />}>
                  {/* Domain Analysis */}
                  <Route path="/overview" element={
                    <ProtectedRoute>
                      <DomainSearch />
                    </ProtectedRoute>
                  } />
                  <Route path="/overview/results" element={
                    <ProtectedRoute>
                      <DomainOverview />
                    </ProtectedRoute>
                  } />
                  <Route path="/backlinks" element={
                    <ProtectedRoute>
                      <BacklinkAnalysis />
                    </ProtectedRoute>
                  } />
                  <Route path="/domain" element={
                    <ProtectedRoute>
                      <DomainAnalysis />
                    </ProtectedRoute>
                  } />
                  <Route path="/competitors" element={
                    <ProtectedRoute>
                      <CompetitorDomains />
                    </ProtectedRoute>
                  } />
                  <Route path="/referring-domains" element={
                    <ProtectedRoute>
                      <ReferringDomains />
                    </ProtectedRoute>
                  } />
                  <Route path="/referring-domains/results" element={
                    <ProtectedRoute>
                      <ReferringDomainsResults />
                    </ProtectedRoute>
                  } />
                  <Route path="/backlink-gap" element={
                    <ProtectedRoute>
                      <BacklinkGap />
                    </ProtectedRoute>
                  } />
                  <Route path="/backlink-gap/results" element={
                    <ProtectedRoute>
                      <BacklinkGapResults />
                    </ProtectedRoute>
                  } />

                  {/* Keyword Research */}
                  <Route path="/seed" element={
                    <ProtectedRoute>
                      <SeedKeywordSearch />
                    </ProtectedRoute>
                  } />
                  <Route path="/seed/results" element={
                    <ProtectedRoute>
                      <SeedKeywordResults />
                    </ProtectedRoute>
                  } />
                  <Route path="/keyword-lists" element={
                    <ProtectedRoute>
                      <KeywordLists />
                    </ProtectedRoute>
                  } />
                  <Route path="/keyword-lists/:id" element={
                    <ProtectedRoute>
                      <ListDetails />
                    </ProtectedRoute>
                  } />
                  <Route path="/keyword-gap" element={
                    <ProtectedRoute>
                      <KeywordGap />
                    </ProtectedRoute>
                  } />
                  <Route path="/keyword-gap/results" element={
                    <ProtectedRoute>
                      <KeywordGapResults />
                    </ProtectedRoute>
                  } />
                  <Route path="/keyword-overview" element={
                    <ProtectedRoute>
                      <KeywordOverview />
                    </ProtectedRoute>
                  } />
                  <Route path="/keyword-overview/results" element={
                    <ProtectedRoute>
                      <KeywordOverviewResults />
                    </ProtectedRoute>
                  } />
                  <Route path="/ranked-keywords" element={
                    <ProtectedRoute>
                      <RankedKeywords />
                    </ProtectedRoute>
                  } />
                  <Route path="/ranked-keywords/results" element={
                    <ProtectedRoute>
                      <RankedKeywordsResults />
                    </ProtectedRoute>
                  } />

                  {/* SERP Analysis */}
                  <Route path="/serp" element={
                    <ProtectedRoute>
                      <SerpKeywordSearch />
                    </ProtectedRoute>
                  } />
                  <Route path="/serp/results" element={
                    <ProtectedRoute>
                      <SerpKeywordResults />
                    </ProtectedRoute>
                  } />
                  <Route path="/serp-checker" element={
                    <ProtectedRoute>
                      <SerpChecker />
                    </ProtectedRoute>
                  } />
                  <Route path="/serp-checker/results" element={
                    <ProtectedRoute>
                      <SerpCheckerResults />
                    </ProtectedRoute>
                  } />
                  <Route path="/serp-checker/analysis" element={
                    <ProtectedRoute>
                      <SerpAnalysis />
                    </ProtectedRoute>
                  } />

                  {/* Pages Analysis */}
                  <Route path="/pages" element={
                    <ProtectedRoute>
                      <TopPagesSearch />
                    </ProtectedRoute>
                  } />
                  <Route path="/pages/results" element={
                    <ProtectedRoute>
                      <TopPagesResults />
                    </ProtectedRoute>
                  } />
                  <Route path="/pages-by-links" element={
                    <ProtectedRoute>
                      <PagesByLinks />
                    </ProtectedRoute>
                  } />
                  <Route path="/pages-by-links/results" element={
                    <ProtectedRoute>
                      <PagesByLinksResults />
                    </ProtectedRoute>
                  } />

                  {/* Miscellaneous */}
                  <Route path="/botox" element={
                    <ProtectedRoute>
                      <Botox />
                    </ProtectedRoute>
                  } />
                  <Route path="/botox/results" element={
                    <ProtectedRoute>
                      <BotoxResults />
                    </ProtectedRoute>
                  } />
                  <Route path="/traffic-share" element={
                    <ProtectedRoute>
                      <TrafficShare />
                    </ProtectedRoute>
                  } />
                  <Route path="/traffic-share/results" element={
                    <ProtectedRoute>
                      <TrafficShareResults />
                    </ProtectedRoute>
                  } />
                  <Route path="/ppa" element={
                    <ProtectedRoute>
                      <PPA />
                    </ProtectedRoute>
                  } />
                  <Route path="/ppa/results" element={
                    <ProtectedRoute>
                      <PPAResults />
                    </ProtectedRoute>
                  } />
                  <Route path="/performance" element={
                    <ProtectedRoute>
                      <Performance />
                    </ProtectedRoute>
                  } />
                  <Route path="/performance/results" element={
                    <ProtectedRoute>
                      <PerformanceResults />
                    </ProtectedRoute>
                  } />
                  <Route path="/competitor-analysis" element={
                    <ProtectedRoute>
                      <CompetitorAnalysisSearch />
                    </ProtectedRoute>
                  } />
                  <Route path="/competitor-analysis/results" element={
                    <ProtectedRoute>
                      <CompetitorAnalysisResults />
                    </ProtectedRoute>
                  } />
                  {/* Account */}
                  <Route path="/settings" element={
                    <ProtectedRoute>
                      <Settings />
                    </ProtectedRoute>
                  } />
                  <Route path="/subscription" element={
                    <ProtectedRoute>
                      <Subscription />
                    </ProtectedRoute>
                  } />
                </Route>

                {/* 404 Route */}
                <Route path="*" element={<NotFound />} />
              </Routes>
              <Toaster 
                position="top-right"
                toastOptions={{
                  className: 'dark:bg-gray-800 dark:text-white'
                }}
              />
            </CreditsProvider>
          </Router>
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}
