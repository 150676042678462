import React from 'react';
import { Download } from 'lucide-react';
import { DomainAnalysisButton } from './DomainAnalysisButton';
import { ShareButton } from './ShareButton';

interface DomainOverviewHeaderProps {
  domain: string;
  onAnalyze: () => void;
  onExport: () => void;
  isLoading: boolean;
  isStoredData: boolean;
  historyId?: string;
}

export function DomainOverviewHeader({ 
  domain, 
  onAnalyze, 
  onExport, 
  isLoading,
  isStoredData,
  historyId
}: DomainOverviewHeaderProps) {
  return (
    <div className="flex justify-between items-center">
      <div>
        <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
          Domain Overview: {domain}
        </h1>
        {isStoredData && (
          <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
            Showing saved analysis data
          </p>
        )}
      </div>
      <div className="flex items-center space-x-4">
        {isStoredData && (
          <DomainAnalysisButton onClick={onAnalyze} isLoading={isLoading} />
        )}
        {historyId && isStoredData && (
          <ShareButton historyId={historyId} />
        )}
        <button
          onClick={onExport}
          disabled={isLoading}
          className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <Download className="h-4 w-4 mr-2" />
          Export PDF
        </button>
      </div>
    </div>
  );
}
