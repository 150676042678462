import { supabase } from '../config/supabase';
import axios from 'axios';

export async function getCustomDomain(): Promise<string | null> {
  try {
    const { data, error } = await supabase.rpc('get_or_create_user_settings');

    if (error) throw error;
    return data?.custom_domain || null;
  } catch (error) {
    console.error('Error getting custom domain:', error);
    throw error;
  }
}

export async function updateCustomDomain(domain: string): Promise<void> {
  try {
    // Clean domain
    const cleanDomain = domain.trim().toLowerCase();
    
    if (cleanDomain) {
      // First verify DNS configuration
      const dnsVerified = await verifyDNS(cleanDomain);
      if (!dnsVerified) {
        throw new Error('DNS verification failed. Please ensure your CNAME record points to seo.semdash.com');
      }

      // Verify SSL certificate
      const sslVerified = await verifySSL(cleanDomain);
      if (!sslVerified) {
        throw new Error('SSL verification failed. Please wait a few minutes for the SSL certificate to be provisioned.');
      }
    }

    const { error } = await supabase.rpc('update_custom_domain', {
      p_domain: cleanDomain
    });

    if (error) throw error;
  } catch (error) {
    console.error('Error updating custom domain:', error);
    throw error;
  }
}

async function verifyDNS(domain: string): Promise<boolean> {
  try {
    // Use DNS resolution to check CNAME record
    const response = await axios.get(`https://dns.google/resolve?name=${domain}&type=CNAME`);
    
    // Check if CNAME record points to our domain
    const hasCorrectCNAME = response.data?.Answer?.some(record => 
      record.data === 'seo.semdash.com.' || 
      record.data === 'seo.semdash.com'
    );

    if (!hasCorrectCNAME) {
      throw new Error('CNAME record not found or incorrect. Please add a CNAME record pointing to seo.semdash.com');
    }

    return true;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.code === 'ECONNREFUSED' || error.code === 'ENOTFOUND') {
        throw new Error('Domain is not accessible. Please check your DNS settings.');
      }
    }
    throw error;
  }
}

async function verifySSL(domain: string): Promise<boolean> {
  try {
    // Try to fetch the domain with HTTPS
    await axios.get(`https://${domain}`, {
      timeout: 5000,
      validateStatus: (status) => status < 500 // Allow any status < 500
    });
    return true;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.code === 'CERT_INVALID') {
        throw new Error('SSL certificate is not valid. Please wait a few minutes for it to be provisioned.');
      }
      if (error.code === 'ECONNREFUSED') {
        throw new Error('Could not establish HTTPS connection. Please ensure SSL is properly configured.');
      }
    }
    return false;
  }
}
