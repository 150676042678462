import React from 'react';
import { NavLink } from 'react-router-dom';
import { Home, Network, Link2, Search,Layers, BarChart2, Users, FileText, KeySquare, Sprout, Link as LinkIcon, History, Settings, LogOut, HelpCircle, Share, List, CreditCard } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

export function Sidebar() {
  const { logout } = useAuth();
  const sections = [
    {
      title: 'Competitve Research',
      items: [
        { icon: Home, label: 'Domain Overview', path: '/overview' },
        { icon: History, label: 'SERP Checker', path: '/serp-checker' },
        { icon: Users, label: 'Keyword Gap', path: '/keyword-gap' },
        { icon: LinkIcon, label: 'Backlink Gap', path: '/backlink-gap' },
        { icon: Share, label: 'Traffic Share', path: '/traffic-share' },
        { icon: Users, label: 'Competitor Domains', path: '/competitor-analysis' },
        { icon: FileText, label: 'Top Pages', path: '/pages' },
        { icon: BarChart2, label: 'Performance', path: '/performance' },
      ]
    },
    {
      title: 'Keyword Research',
      items: [
        { icon: Search, label: 'Keyword Overview', path: '/keyword-overview' },
        { icon: KeySquare, label: 'Ranked Keywords', path: '/ranked-keywords' },
        { icon: Sprout, label: 'Longtail keywords', path: '/seed' },
        { icon: Network, label: 'People Also Search', path: '/serp' },
        { icon: HelpCircle, label: 'People Also Ask', path: '/ppa' },
        { icon: List, label: 'Keyword Lists', path: '/keyword-lists' }
      ]
    },
    {
      title: 'Backlink Research',
      items: [
        { icon: Link2, label: 'Backlinks', path: '/botox' },
        { icon: LinkIcon, label: 'Referring Domains', path: '/referring-domains' },
        { icon: Layers, label: 'Pages By Links', path: '/pages-by-links' },
      ]
    }
  ];

  return (
    <div className="h-screen w-64 bg-[#f9fafb] dark:bg-gray-900 border-r border-gray-200 dark:border-gray-700 flex flex-col">
      <div className="p-4 border-b border-gray-200 dark:border-gray-700">
        <div 
          onClick={() => window.location.href = 'https://seo.semdash.com/overview'}
          className="cursor-pointer"
        >
          <img 
            src="https://app.semdash.com/dist/img/SeoLogo.webp" 
            alt="Semdash Logo" 
            className="h-8 dark:invert"
          />
        </div>
      </div>
      
      <nav className="flex-1 p-4 space-y-8 overflow-y-auto">
        {sections.map((section, index) => (
          <div key={index} className="space-y-2">
            <h3 className="text-xs font-medium text-gray-400 dark:text-gray-500 uppercase tracking-wider px-4">
              {section.title}
            </h3>
            <ul className="space-y-1">
              {section.items.map((item) => (
                <li key={item.path}>
                  <NavLink
                    to={item.path}
                    className={({ isActive }) =>
                      `flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-colors ${
                        isActive 
                          ? 'bg-[#dbf3ff] text-[#0081dd] dark:bg-blue-900/50 dark:text-blue-200 shadow-[0_1px_3px_rgba(0,0,0,0.12),0_1px_2px_rgba(0,0,0,0.24)]' 
                          : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800'
                      }`
                    }
                  >
                    <item.icon className="w-5 h-5 mr-3" />
                    {item.label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </nav>

      <div className="p-4 border-t border-gray-200 dark:border-gray-700">
        <button
          onClick={logout}
          className="flex items-center w-full px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800"
        >
          <LogOut className="w-5 h-5 mr-3" />
          Logout
        </button>
      </div>
    </div>
  );
}
